/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import Element from "../Element";
import Label from "../Label";
import React from "react";
import "./Booklist.css";

// Book

const Book = ({ book }) => {
  const title = book ? book.title.split(":")[0] : "";

  return (
    <Element className="Book">
      <a className="cover-link" href={book.url}>
        <figure className="aspect-cover relative">
          <img
            alt={title}
            src={book.main_image}
            sizes="(min-width: 600px) 200px, 150px"
          />
          <figcaption as="h6" className="title">
            {title}
          </figcaption>
        </figure>
      </a>
    </Element>
  );
};

export default Book;
