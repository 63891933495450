/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import MaxWidthContainer from "../MaxWidthContainer";
import React from "react";
import "./PageHeader.css";
import Row from "../Row";

// PageHeader

const PageHeader = () => {
  const MobileContent = () => {
    return (
      <Row className="mobile-content-container">
        <a href="https://andrewburnett.info" className="titles row">
          <h1 className="title">Andrew Burnett</h1>
          <h2 className="subtitle">Engineer / researcher</h2>
        </a>
      </Row>
    );
  };

  const DesktopContent = () => {
    return (
      <Row className="desktop-content-container">
        <a href="https://andrewburnett.info" className="titles row">
          <h1 className="title">Andrew Burnett</h1>
          <h2 className="subtitle">Software engineer / Bitcoin researcher</h2>
        </a>
      </Row>
    );
  };

  return (
    <div className="PageHeader">
      <MaxWidthContainer className="container">
        <MobileContent />
        <DesktopContent />
      </MaxWidthContainer>
    </div>
  );
};

export default PageHeader;
