/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import ClipLoader from "react-spinners/ClipLoader";
import React from "react"
import Row from "../Row"
import "./Loading.css"

// Loading

const Loading = ({ }) => {
    return (
        <Row className="Loading" alignItems="center" justifyContent="center">
            <ClipLoader
              size={24}
              color={"#000000"}
            />
        </Row>
    )
}

export default Loading;