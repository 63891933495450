/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import PageHeader from "../PageHeader";
import React from "react"
import "./Page.css"

// Page

const Page = ({ children, className }) => {

    let cn = className ? `Page ${className}` : 'Page'

    return (
        <div className={cn}>
            <PageHeader />
            {children}
        </div>
    )
}

export default Page;