/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import MaxWidthContainer from "../../components/MaxWidthContainer";
import PageSubheader from "../../components/PageSubheader";
import Booklist from "../../components/Booklist";
import Page from "../../components/Page";
import "./Booklist.css";

// Booklist Page

const BooklistPage = () => {
  return (
    <Page className="BooklistPage">
      <MaxWidthContainer>
        <PageSubheader title={"What I've been reading"} />
        <Booklist />
      </MaxWidthContainer>
    </Page>
  );
};

export default BooklistPage;
