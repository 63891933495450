/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import MaxWidthContainer from "../MaxWidthContainer"
import React from "react"
import "./Footer.css"

// Footer

const Footer = ({ }) => {

    return (
        <div className="Footer">
            <MaxWidthContainer className="container">
                <h6 className="copyright">©2023 Andrew Burnett — Book List </h6>
            </MaxWidthContainer>
        </div>
    )
}

export default Footer;