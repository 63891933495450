/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import { useCollection } from "react-firebase-hooks/firestore"
import { query, collection } from "firebase/firestore"
import { useEffect, useState } from "react"
import { db } from "../clients/firebase"

// useUserBooks hook

const useUserBooks = () => {

    // Construct our owned_products query
    const [userBooksQuery, setUserBooksQuery] = useState(null)
    const [userBooks, setUserBooks] = useState([]);
    const [userBooksSnapshots, userBooksLoading, userBooksError] = useCollection(userBooksQuery);

    useEffect(() => {
        if (userBooksSnapshots) {
            let newUserBooks = []
            userBooksSnapshots.docs.forEach((doc) => {
                newUserBooks.push({ doc_id: doc.id, ...doc.data() })
            })
            setUserBooks(newUserBooks)
        }
    }, [userBooksSnapshots])

    // Create the query to get the user's books
    useEffect(() => {
        const aQuery = query(collection(db, "books"))
        setUserBooksQuery(aQuery)
    }, [])

    return [userBooks, userBooksLoading, userBooksError]
}

export default useUserBooks;