/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import useUserBooks from "../../hooks/useUserBooks";
import Loading from "../Loading";
import Book from "./Book";
import React from "react";
import "./Booklist.css";

// Booklist

const Booklist = () => {
  const [books, booksLoading,] = useUserBooks();

  if (booksLoading) {
    return <Loading />;
  } else {
    return (
      <div className="Booklist">
        {(books && !booksLoading) && books.map((book) => {
                return <Book key={`book-${book.asin}`} book={book} />
            })}
      </div>
    );
  }
};

export default Booklist;
