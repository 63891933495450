/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BooklistPage from "./modules/Booklist";
import Footer from "./components/Footer";
import "./App.css";

// App

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<BooklistPage />} />
        </Routes>
      </BrowserRouter>
      <Footer />
      <ToastContainer
        position="bottom-right"
        closeOnClick
      />
    </div>
  );
}

export default App;
