// Imports

import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";
import { handleError } from '../utils/errors'
import { initializeApp } from "firebase/app"
import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
    connectAuthEmulator
} from "firebase/auth";
import {
    getFirestore,
    connectFirestoreEmulator,
    query,
    getDocs,
    collection,
    where,
    addDoc,
} from "firebase/firestore";

// Config

const firebaseConfig = {
    apiKey: "AIzaSyA3x21DWpUVF8KR9tW6wIQWsYgDMAZ4n9o",
    authDomain: "bookslist-fd3db.firebaseapp.com",
    projectId: "bookslist-fd3db",
    storageBucket: "bookslist-fd3db.appspot.com",
    messagingSenderId: "460486001682",
    appId: "1:460486001682:web:3be6ef48b859574ed5c4cb",
    measurementId: "G-5308C8BJM3"
  };

// Initialize our Firebase app and services

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app)
const functions = getFunctions(app);

if (window.location.hostname === 'localhost') {
    console.log('Using TEST environment.')
    connectAuthEmulator(auth, "http://localhost:9099")
    connectFirestoreEmulator(db, 'localhost', 8080);
    connectFunctionsEmulator(functions, "localhost", 5001);
}

//
// AUTH
// 

// Log out
const logout = () => {
    signOut(auth)
}

// Sign-in/Register: Google Log In
const googleProvider = new GoogleAuthProvider()
const signInWithGoogle = async () => {
    try {
        const res = await signInWithPopup(auth, googleProvider)
        const user = res.user
        const q = query(collection(db, "users"), where("uid", "==", user.uid))
        const docs = await getDocs(q)
        if (docs.docs.length === 0) {
            await addDoc(collection(db, "users"), {
                uid: user.uid,
                name: user.displayName,
                authProvider: "google",
                email: user.email
            })
        }
    } catch (err) {
        console.log('ERROR', err)
        // alert(err.message)
        handleError(err)
    }
}

// Sign-in: Email and password
const logInWithEmailAndPassword = async (email, password) => {
    try {
        await signInWithEmailAndPassword(auth, email, password)
    } catch (err) {
        console.log('Error', err)
        // alert(err.message)
        handleError(err)
    }
}

// Register: Email and password
const registerWithEmailAndPassword = async (name, email, password) => {
    try {
        const res = await createUserWithEmailAndPassword(auth, email, password)
        const user = res.user
        await addDoc(collection(db, "users"), {
            uid: user.uid,
            name,
            authProvider: "local",
            email
        })
    } catch (err) {
        console.log(err)
        // alert(err.message)
        handleError(err)
    }
}

// Reset: Email and password
const sendPasswordReset = async (email) => {
    try {
        await sendPasswordResetEmail(auth, email)
        alert("Password reset link sent!")
    } catch (err) {
        console.log(err)
        // alert(err.message)
        handleError(err)
    }
}

// 
// CALLABLES
// 

const addUserOwnedProduct = httpsCallable(functions, 'owned_products-addUserOwnedProduct');

//
// EXPORTs
// 

export {
    auth,
    db,
    signInWithGoogle,
    logInWithEmailAndPassword,
    registerWithEmailAndPassword,
    sendPasswordReset,
    logout,
    addUserOwnedProduct
}